import HttpInterceptor from "./HttpInterceptor";

export default {

  name: 'TokenService',

  processToken(user, token) {
    return HttpInterceptor.httpRecaptchaClient().post('token/process/' + token, user);
  },
}
