<template>
  <div v-if="!isAuthenticated">
    <div class="grid-x grid-padding-x">
      <div class="cell callout success">
        <h3>
          <md-icon style="color: black; font-size: 2rem!important; padding-left: 1rem;">person_outline</md-icon>
          <md-icon style="color: black; font-size: 2rem!important; padding-left: 1rem;">check</md-icon>
          &nbsp;&nbsp;&nbsp;Konto aktivieren</h3>
      </div>
    </div>

    <div class="grid-x grid-padding-x grid-padding-y">
      <div v-if="!accountActivated" class="cell">
        <md-card>
          <md-card-header>
            <div class="grid-x grid-padding-x">
              <div class="cell">
                <div class="md-title">
                  <span>Software für Soziale Einrichtungen (SoSE)&nbsp;&nbsp;&nbsp;<strong>Aktivierung</strong></span>
                </div>
                <br>
                <div>
                  <div class="md-title-textarea text-justify">
                    Sie haben es fast geschafft!
                    Bitte bestätigen Sie uns jetzt die Aktivierung.
                    Gleich danach kann es sofort losgehen!
                    <br><br><br>
                  </div>
                </div>
              </div>
            </div>
          </md-card-header>

          <md-card-content>

            <div class="grid-x">
              <div class="cell large-4 medium-2 small-0"></div>
              <div class="cell large-4 medium-8 small-12">
                <vue-recaptcha
                    ref="recaptcha"
                    :sitekey="siteKey"
                    @verify="onVerify"
                    @expired="onExpired">
                </vue-recaptcha>
                <br><br><br>
                <div class="cell">
                  <button class="button warn" style="width: 304px;" :disabled="recaptchaResponse.length === 0 || sending" @click="onActivateAccount()">Konto jetzt aktivieren</button>
                  <md-tooltip><p v-if="recaptchaResponse.length === 0">Bitte Bestätigen Sie das CAPTCHA.</p></md-tooltip>
                </div>
              </div>
              <div class="cell large-4 medium-2 small-0 text-center">
                <div v-if="sending" class="md-result-textarea grid-x">
                  <div class="cell large-2 text-center">
                    <vue-simple-spinner></vue-simple-spinner>
                  </div>
                  <div class="cell large-10 hide-for-medium-only hide-for-small-only text-left" style="padding-top: 5px;">
                    &nbsp;Aktivierung ...
                  </div>
                </div>
              </div>
            </div>

          </md-card-content>

        </md-card>
      </div>
      <div v-else class="cell">
        <md-card style="height: 100%">
          <md-card-header>
            <div class="grid-x grid-padding-x">
              <div class="cell">
                <div class="md-title">
                  <span>Software für Soziale Einrichtungen (SoSE)&nbsp;&nbsp;&nbsp;<strong>Aktivierung</strong></span>
                </div>
                <br>
                <div class="md-title text-center">
                  <br><br>
                  <p style="font-size: 30px; color: darkgreen">Ihr Konto ist nun aktiv!&nbsp;</p>
                  <br>
                  <md-icon style="font-size: 3rem !important; color: forestgreen;">person_outline</md-icon>&nbsp;&nbsp;
                  <md-icon style="font-size: 3rem !important; color: forestgreen; font-weight: 600;">check</md-icon>
                  <br><br><br>
                </div>
              </div>
            </div>
          </md-card-header>

          <md-card-content>

            <div class="grid-x">
              <div class="cell large-4 medium-2 small-0"></div>
              <div class="cell large-4 medium-8 small-12">
                <div class="cell text-center">
                  <router-link to="/Login">
                    <button class="button warn" style="width: 300px;"><i class="fi-torso"></i>&nbsp;<i class="fi-key"></i>&nbsp;&nbsp;&nbsp;Zur Anmeldung</button>
                    <md-tooltip><p>Mit neuem Konto anmelden.</p></md-tooltip>
                  </router-link>
                </div>
                <br>
              </div>
              <div class="cell large-4 medium-2 small-0 text-center">
              </div>
            </div>

          </md-card-content>

        </md-card>
      </div>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="error">
      <md-dialog-title style="color: red">Es ist ein Fehler aufgetreten</md-dialog-title>

      <md-dialog-content class="md-result-textarea text-justify">
        Beim Verarbeiten Ihrer Anfrage ist ein Fehler aufgetreten.
        Bitte versuchen es erneut.<br><br>
        Alternativ können Sie uns unter der <a :href= "registerEmailMessage" target="_blank">Email-Adresse <b>contact@ai42.de</b> kontaktieren</a>.
      </md-dialog-content>

      <div class="text-center">
        <button class="button md-primary" @click="error = false">Verstanden</button>
      </div>
    </md-dialog>
  </div>
</template>

<script>
  import Spinner from 'vue-simple-spinner';
  import VueRecaptcha from 'vue-recaptcha';
  import TokenService from '../services/TokenService';
  import HttpErrorHandler from '../services/HttpErrorHandler';

  export default {
    name: 'AccountActivation',

    components: {
      'vue-simple-spinner': Spinner,
      VueRecaptcha,
    },

    mounted() {
      this.token = this.$route.params['token'];
    },

    data: () => ({
      sending: false,
      error: false,
      siteKey: process.env.VUE_APP_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaResponse: '',
      accountActivated: false,
      token: '',
      registerEmailMessage: 'mailto:contact@ai42.de?subject=SoSE-Registrierungsanfrage&body=Sehr%20geehrte%20Damen%20und%20Herren,%20wir%20möchten%20unser%20Konto%20für%20SoSE%20aktivieren.%20Bitte%20kontaktieren%20Sie%20uns%20hierfür%20per%20email.%20Danke%20und%20Grüße',
    }),

    methods: {

      onVerify: function (response) {
        this.recaptchaResponse = response;
        localStorage.setItem('x-recaptcha', response);
      },

      onExpired: function () {
        this.resetCaptcha();
      },

      resetCaptcha() {
        this.recaptchaResponse = '';
        localStorage.removeItem('x-recaptcha');
      },

      onActivateAccount() {
        this.activateAccount({user: {}}, this.token);
      },

      activateAccount(user, token) {
        this.sending = true;
        this.error = false;

        TokenService.processToken(user, token)
          .then(() => {
            this.accountActivated = true;
            this.sending = false;
            this.resetCaptcha();
            this.$refs.recaptcha.reset();
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Aktivieren des Kontos');
            this.sending = false;
            this.error = true;
            this.resetCaptcha();
            this.$refs.recaptcha.reset();
          });
      },
    },
    
    computed: {
      isAuthenticated() {
        return this.$store.getters.authenticated
      },
    }
  }
</script>

<style lang="scss" scoped>
  span {
    color: cornflowerblue;
  }

  strong {
    font-weight: 400;
    color: orange;
  }

  p {
    margin: 0;
  }

  .md-textarea {
    font-family: 'Helvetica Neue', Helvetica, Roboto, Arial, sans-serif;
    font-size: 1.3rem;
    line-height: 2rem;
    font-weight: 300;
    color: black;
  }

  .md-title-textarea {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    font-size: 1.2rem;
    line-height: 1.6rem;
    font-weight: 300;
    color: darkslategrey;
  }

  .md-tooltip {
    font-size: large;
    text-align: left;
    height: auto;
    max-width: 380px;
    white-space: pre-wrap;
  }

  .md-tooltip b {
    color: greenyellow;
  }
</style>